import { FronteggNextJSSession } from '@frontegg/nextjs';
import { withFronteggApp } from '@frontegg/nextjs/pages';
import '@mantine/carousel/styles.css';
import '@mantine/charts/styles.css';
import { MantineProvider } from '@mantine/core';
import '@mantine/core/styles.css';
import '@mantine/dates/styles.css';
import { Notifications } from '@mantine/notifications';
import '@mantine/notifications/styles.css';
import '@mantine/nprogress/styles.css';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import {
  Analytics,
  IntercomWidget,
  LoadingOverlayProvider,
  SupportInformation,
} from '@zorro/shared/ui';
import { useAppProgressBar } from '@zorro/shared/utils';
import { NavigationProgress, Theme } from '@zorro/zorro-ui-design';
import { AppProps } from 'next/app';
import Head from 'next/head';
import { ReactNode, useEffect, useState } from 'react';
import { DefaultLayout } from '~/components/layouts/DefaultLayout';

import '../styles/global.css';

function ClientSideRender({ children }: { children: ReactNode }) {
  const [csrReady, setCsrReady] = useState(false);

  // 🧠 useEffect ensures the children are rendered in the browser
  useEffect(() => {
    setCsrReady(true);
  }, []);

  return csrReady ? children : null;
}

interface ZorroAppProps extends AppProps<{ session?: FronteggNextJSSession }> {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  Component: AppProps['Component'] & {
    getLayout?: (page: ReactNode) => ReactNode;
  };
}

// eslint-disable-next-line @typescript-eslint/naming-convention
function ZorroApp({ Component, pageProps }: ZorroAppProps) {
  useAppProgressBar();

  // eslint-disable-next-line react/hook-use-state
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
            staleTime: 60_000,
            retry: false,
          },
        },
      })
  );

  const getLayout =
    Component.getLayout ??
    ((page: ReactNode) => <DefaultLayout>{page}</DefaultLayout>);

  return (
    <ClientSideRender>
      <Head>
        <title>Zorro</title>
      </Head>

      <QueryClientProvider client={queryClient}>
        <MantineProvider theme={Theme}>
          <LoadingOverlayProvider>
            <IntercomWidget />
            <SupportInformation />
            <Analytics applicationName="elena" />
            <NavigationProgress />
            <Notifications position="top-right" />
            {getLayout(<Component {...pageProps} />)}
          </LoadingOverlayProvider>
        </MantineProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </ClientSideRender>
  );
}

export default withFronteggApp(ZorroApp, {
  hostedLoginBox: true,
  lazyLoadAdminPortal: true,
  authOptions: {
    keepSessionAlive: true,
  },
  themeOptions: {
    adminPortal: {
      pages: {
        profile: {
          fieldsProperties: {
            name: {
              appearance: 'hidden',
            },
            phoneNumber: {
              appearance: 'hidden',
            },
            address: {
              appearance: 'hidden',
            },
            jobTitle: {
              appearance: 'hidden',
            },
          },
        },
        privacy: {
          fieldsProperties: {
            loginSessions: {
              appearance: 'hidden',
            },
          },
        },
      },
    },
  },
});
