import { getFullName, useImpersonation } from '@zorro/shared/utils';
import { HEADER_HEIGHT } from '@zorro/types';
import { AppShell, AppShellProps } from '@zorro/zorro-ui-design';
import { ReactNode } from 'react';
import { HeaderTopBar } from '~/components/layouts/HeaderTopBar';

interface Props extends AppShellProps {
  children: ReactNode;
  shouldRemovePadding?: boolean;
  isDon?: boolean;
}

export function DefaultLayout({
  children,
  shouldRemovePadding,
  isDon = false,
  ...props
}: Props) {
  const { user, employee, isImpersonated } = useImpersonation();

  if (!user || !employee) {
    return children;
  }

  const paddingProps = shouldRemovePadding
    ? // eslint-disable-next-line id-length
      { pb: 0, mainProps: { p: 0, pt: HEADER_HEIGHT } }
    : {};

  return (
    <AppShell
      headerComponent={
        <HeaderTopBar
          employerId={employee.employerId}
          isAuthenticated={Boolean(user)}
          userRoles={user.roles}
          profilePicture={user.profilePictureUrl}
          isImpersonated={isImpersonated}
          isDon={isDon}
        />
      }
      impersonatedEmployeeFullName={
        isImpersonated && user ? getFullName(user) : undefined
      }
      {...paddingProps}
      {...props}
    >
      {children}
    </AppShell>
  );
}
