import { IconMenu2 } from '@tabler/icons-react';
import { useQuery } from '@tanstack/react-query';
import { EmployerSignupStatus, FronteggRolesDto } from '@zorro/clients';
import { EmployerLogo, LogoDark } from '@zorro/shared/ui';
import {
  callEndpoint,
  nullIfNotFound,
  useMobileDetector,
} from '@zorro/shared/utils';
import {
  ActionIcon,
  Box,
  Divider,
  Flex,
  Group,
  brand,
} from '@zorro/zorro-ui-design';
import { Dispatch, SetStateAction } from 'react';
import { ZorroUiUser } from '~/components/layouts/ZorroUiUser';

interface HeaderTopBarProps {
  isAuthenticated: boolean;
  employerId: string;
  userRoles: FronteggRolesDto[];
  profilePicture?: string | null;
  isImpersonated?: boolean;
  isDon?: boolean;
  isSidebarVisible?: boolean;
  setIsSidebarVisible?: Dispatch<SetStateAction<boolean>>;
}

export const HeaderTopBar = ({
  isAuthenticated,
  employerId,
  userRoles,
  profilePicture,
  isImpersonated = false,
  isDon = false,
  isSidebarVisible = false,
  setIsSidebarVisible,
}: HeaderTopBarProps) => {
  const { isMobile } = useMobileDetector();
  const { data: employerPublicInfo } = useQuery({
    queryKey: ['EmployerPublicInfo', isAuthenticated, employerId],
    queryFn: async () => {
      if (!employerId) {
        return null;
      }

      return isAuthenticated
        ? await callEndpoint({
            method: 'employersControllerFindEmployerPublicInfo',
            params: [employerId],
          })
        : await nullIfNotFound(() =>
            callEndpoint({
              method: 'healthControllerAnonymousFindEmployerPublicInfo',
              params: [employerId],
              anonymous: true,
            })
          );
    },
  });

  const { logoUrl, signupStatus } = employerPublicInfo || {};

  return (
    <Group justify="space-between" align="center" wrap="nowrap" h="100%">
      {isMobile && setIsSidebarVisible && (
        <ActionIcon
          onClick={() => setIsSidebarVisible(!isSidebarVisible)}
          variant="transparent"
          bg={isSidebarVisible ? brand.primaryButtonSelected : undefined}
          style={{ borderRadius: '0.25rem' }}
          size="lg"
          ml="xs"
        >
          <IconMenu2 />
        </ActionIcon>
      )}
      <Box>{logoUrl && <EmployerLogo logoUrl={logoUrl} />}</Box>
      <Flex align="center">
        <Box
          display={{
            base: isAuthenticated ? 'none' : 'inherit',
            sm: 'inherit',
          }}
        >
          <LogoDark />
        </Box>
        {isAuthenticated && (
          <>
            <Divider
              display={{ base: 'none', sm: 'inherit' }}
              className="excluded-from-print"
              orientation="vertical"
              mx={32}
              my={7}
            />
            <ZorroUiUser
              userRoles={userRoles}
              profilePicture={profilePicture}
              isImpersonated={isImpersonated}
              shouldShowNavigationOptions={
                signupStatus === EmployerSignupStatus.ACTIVE
              }
              isDon={isDon}
            />
          </>
        )}
      </Flex>
    </Group>
  );
};
