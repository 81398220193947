import { AdminPortal } from '@frontegg/nextjs';
import {
  IconBriefcase,
  IconDoorExit,
  IconSettings,
  IconUser,
} from '@tabler/icons-react';
import { FronteggRolesDto } from '@zorro/clients';
import {
  DON_ROUTES,
  ZORRO_UI_DYNAMIC_ROUTES,
  logout,
  useMobileDetector,
  useRouter,
} from '@zorro/shared/utils';
import { Roles } from '@zorro/types';
import { Avatar, Icon, Menu, UnstyledButton } from '@zorro/zorro-ui-design';

type Props = {
  userRoles: FronteggRolesDto[];
  profilePicture?: string | null;
  shouldShowNavigationOptions?: boolean;
  isImpersonated?: boolean;
  isDon?: boolean;
};

export const ZorroUiUser = ({
  userRoles,
  profilePicture,
  shouldShowNavigationOptions = false,
  isDon = false,
  isImpersonated = false,
}: Props) => {
  const { isMobile } = useMobileDetector();
  const router = useRouter();

  const redirectToAdminView = () => {
    router.pushWithCurrentQueryParams(DON_ROUTES.COMPANY);
  };

  const redirectToPersonalView = () => {
    router.pushWithCurrentQueryParams(ZORRO_UI_DYNAMIC_ROUTES.MY_COVERAGE());
  };

  const isEmployerAdmin = userRoles.some((role) =>
    [Roles.EMPLOYER_ADMIN].some((allowedRole) => allowedRole.includes(role.key))
  );
  const isEmployee = userRoles.some((role) =>
    [Roles.EMPLOYEE].some((allowedRole) => allowedRole.includes(role.key))
  );

  if (isImpersonated && !isEmployerAdmin) {
    return (
      <Avatar size={48} src={profilePicture} alt="User avatar" radius="xl" />
    );
  }
  return (
    <Menu>
      <Menu.Target>
        <UnstyledButton
          aria-label="Expand user menu tooltip"
          className="excluded-from-print"
        >
          <Avatar
            size={48}
            src={profilePicture}
            alt="User avatar"
            radius="xl"
          />
        </UnstyledButton>
      </Menu.Target>

      <Menu.Dropdown>
        {isEmployerAdmin &&
          isEmployee &&
          shouldShowNavigationOptions &&
          !isMobile && (
            <>
              {isDon && (
                <Menu.Item
                  leftSection={<Icon icon={IconUser} />}
                  onClick={redirectToPersonalView}
                >
                  Personal View
                </Menu.Item>
              )}
              {!isDon && (
                <Menu.Item
                  leftSection={<Icon icon={IconBriefcase} />}
                  onClick={redirectToAdminView}
                >
                  Admin View
                </Menu.Item>
              )}
              {!isImpersonated && <Menu.Divider />}
            </>
          )}

        {!isImpersonated && (
          <Menu.Item
            leftSection={<Icon icon={IconSettings} />}
            onClick={() => AdminPortal.show()}
          >
            Settings
          </Menu.Item>
        )}

        {!isImpersonated && (
          <Menu.Item
            leftSection={<Icon icon={IconDoorExit} />}
            onClick={logout}
          >
            Log Out
          </Menu.Item>
        )}
      </Menu.Dropdown>
    </Menu>
  );
};
